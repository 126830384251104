import React from 'react'
import { Link } from 'gatsby'

const Portfolio = () => {
    return (
        <div>
            <h2>Portfolio</h2>
            <p className="button"> <Link to="/">Home</Link> </p>
        </div>
    )
}

export default Portfolio